import React from 'react'
import Helmet from 'react-helmet'
import Layout from '@components/Layout'
import Hero from '@components/Hero'
import styled from 'styled-components'
import theme, { makeGradient } from '@config/theme';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image'

const StyledHero = styled(Hero)`
  & > * {
    margin-bottom: .5rem;
  }
`

const Wrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-auto-rows: 1fr;

  padding: 0 2rem;

  @media not all and (min-width: 480px) {
    padding: 0;
  }
`

const ProjectCardImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  z-index: -200;
`

const _ProjectCard = styled.div.attrs(({to, href, bgImg, children}) => ({
  as: to ? Link : (href ? 'a' : null),
  target: href && '_blank',
  rel: href && 'noopener',
  children: [bgImg && <ProjectCardImg fluid={bgImg.childImageSharp.fluid}></ProjectCardImg>, ...children],
}))`
  text-decoration: none;

  margin: 1rem;
  padding: 2rem;
  border-radius: 1rem;
  color: ${theme.colors.light.color};
  z-index: 5;

  box-shadow: 0 1rem 5rem -1rem rgba(0,0,0,0.5);
  transform: translateY(0);
  transition: all 400ms ease-in-out;

  :hover {
    box-shadow: 0 1.5rem 7rem -1rem rgba(0,0,0,0.5);
    transform: translateY(-.2rem);
  }

  position: relative;
  overflow: hidden;
  ::after {
    /* Background overlay element */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    z-index: -100;

    /* background: ${theme.colors.light.color}; */
    background: ${makeGradient(theme.colors.dark, '135deg')};

    opacity: ${props => props.bgImg ? 0.8 : 1};
    transition: opacity 400ms ease-in-out;
  }
  :hover::after {
    opacity: ${props => props.bgImg ? .5 : 1};
  }

  min-height: 20rem;
  display: flex;
  flex-direction: column;
  & > * {
    flex: 0 0 auto;
  }
  & > :last-child {
    margin-top: auto;
  }
`

const ProjectCard = ({bgImg, children, ...props}) =>
  <_ProjectCard bgImg={bgImg} {...props}>
    {children}
  </_ProjectCard>

const ProjectDetails = styled.div`
  color: ${theme.colors.light.muted.color};
  font-size: 90%;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  & > :not(:last-child)::after {
    margin: 0 .5em;
    content: '—';
  }
`

const Projects = () => {
  const data = useStaticQuery(graphql`
    query projectsQuery {
      allProjectsYaml (
        sort: {
          fields: [sortPriority, title],
          order: [ASC, DESC]
        }
      ) {
        edges {
          node {
            title
            detail
            desc
            to
            bgImage {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout manualFlex>
      <Helmet>
        <title>Projects</title>
      </Helmet>
      <StyledHero>
        <h2>I make things. Here are my</h2>
        <h1>Projects</h1>
      </StyledHero>
      <Wrapper>
        {data.allProjectsYaml.edges.map(({ node }) =>
          <ProjectCard bgImg={node.bgImage} to={node.to} key={node.title}>
            <h1 key="title">{node.title}</h1>
            <ProjectDetails key="details">{node.detail}</ProjectDetails>
            <div dangerouslySetInnerHTML={{__html: node.desc}} key="desc"></div>
          </ProjectCard>
        )}
      </Wrapper>
    </Layout>
  )
}

export default Projects
